import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

// eslint-disable-next-line react/jsx-props-no-spreading
const LinkStyles = styled(Link)`
  display: inline-block;
  border: none;
  border-radius: 30px;
  background-color: var(--yellow);
  color: var(--darkBlue);
  font-size: 1.25rem;
  font-weight: 700;
  padding: 0.75rem 2rem;
  margin: 0.75rem 1rem;
  text-decoration: none;
  border: 1px solid var(--yellow);
  box-shadow: 0 4px 4px 0 #00000040;

  &:hover,
  &:focus {
    cursor: pointer;
    opacity: 0.7;
    background-color: var(--darkBlue);
    color: var(--yellow);
  }
`

const Button = ({ target, text }) => <LinkStyles to={target}>{text}</LinkStyles>

export default Button
