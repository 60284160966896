import React from 'react'
import { graphql } from 'gatsby'
import { HeroStyles } from '../styles/GlobalStyles'
import SEO from '../components/SEO'
import BackgroundImage from '../components/BackgroundImage'
import Button from '../components/Button'
import Testimonial from '../components/Testimonial'
import bemWordmark from '../assets/logo/bem-wordmark.png'
import { stringsAreEqual, getRandomTestimonial } from '../util/util'

export default function IndexPage({ data }) {
  const heroImage = data?.allFile?.edges?.find((edge) => stringsAreEqual(edge?.node?.name, 'home-hero'))?.node
    ?.childImageSharp?.gatsbyImageData

  const toursImage = data?.allFile?.edges?.find((edge) => stringsAreEqual(edge?.node?.name, 'tours'))?.node
    ?.childImageSharp?.gatsbyImageData

  const taxiImage = data?.allFile?.edges?.find((edge) => stringsAreEqual(edge?.node?.name, 'taxi'))?.node
    ?.childImageSharp?.gatsbyImageData

  const vanImage = data?.allFile?.edges?.find((edge) => stringsAreEqual(edge?.node?.name, 'van'))?.node?.childImageSharp
    ?.gatsbyImageData

  const aboutImage = data?.allFile?.edges?.find((edge) => stringsAreEqual(edge?.node?.name, 'about'))?.node
    ?.childImageSharp?.gatsbyImageData

  const randomTestimonial = getRandomTestimonial(data?.allGoogleSpreadsheetBemTestimonials?.nodes)

  return (
    <>
      <SEO title="Home" />
      <BackgroundImage imageData={heroImage} underHeader fullHeight>
        <HeroStyles>
          <div>
            <h1 className="sr-only">Bird's Eye Maine</h1>
            <img className="logo" src={bemWordmark} alt="Bird's Eye Maine" />
            <p>Giving you access to Maine's beautiful and hard to reach places</p>
            <div className="ctas">
              <Button target="/tours" text="Boat Tours" />
              <Button target="/van" text="Van Rental" />
              <Button target="/taxi" text="Water Taxi" />
              <Button target="/about" text="About BE" />
            </div>
          </div>
        </HeroStyles>
      </BackgroundImage>
      <BackgroundImage imageData={toursImage} fullHeight>
        <HeroStyles>
          <div>
            <h2>Boat Tours</h2>
            <p>Private boat charters of Casco Bay</p>
            <div>
              <Button target="/tours" text="Browse Tours" />
            </div>
          </div>
        </HeroStyles>
      </BackgroundImage>
      <BackgroundImage imageData={taxiImage} fullHeight>
        <HeroStyles>
          <div>
            <h2>Water Taxi</h2>
            <p>Affordable and reliable service in Casco Bay</p>
            <div>
              <Button target="/taxi" text="See Rates" />
            </div>
          </div>
        </HeroStyles>
      </BackgroundImage>
      <BackgroundImage imageData={vanImage} fullHeight>
        <HeroStyles>
          <div>
            <h2>Van Rental</h2>
            <p>Rent your own camper van and explore Maine</p>
            <div>
              <Button target="/van" text="Explore Vans" />
            </div>
          </div>
        </HeroStyles>
      </BackgroundImage>
      <BackgroundImage imageData={aboutImage} fullHeight>
        <HeroStyles>
          <div>
            <h2>About Bird's Eye</h2>
            <p>Learn about all things Bird's Eye</p>
            <div>
              <Button target="/about" text="Learn More" />
            </div>
          </div>
        </HeroStyles>
      </BackgroundImage>
      <Testimonial quote={randomTestimonial?.quote} name={randomTestimonial?.name} />
    </>
  )
}

export const query = graphql`
  {
    allFile(filter: { extension: { regex: "/(jpeg|jpg)/" }, sourceInstanceName: { eq: "background" } }) {
      edges {
        node {
          name
          childImageSharp {
            id
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90, formats: [AUTO, WEBP])
          }
        }
      }
    }
    allGoogleSpreadsheetBemTestimonials {
      nodes {
        id
        name
        quote
      }
    }
  }
`
